/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@font-face {
  font-family: 'Metropolis Extra';
  src: url('assets/fonts/Metropolis-ExtraLight.woff2') format('woff2'),
    url('assets/fonts/Metropolis-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url('assets/fonts/Metropolis-Light.woff2') format('woff2'),
    url('assets/fonts/Metropolis-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url('assets/fonts/Metropolis-Medium.woff2') format('woff2'),
    url('assets/fonts/Metropolis-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis Semi';
  src: url('assets/fonts/Metropolis-SemiBold.woff2') format('woff2'),
    url('assets/fonts/Metropolis-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url('assets/fonts/Metropolis-Regular.woff2') format('woff2'),
    url('assets/fonts/Metropolis-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url('assets/fonts/Metropolis-LightItalic.woff2') format('woff2'),
    url('assets/fonts/Metropolis-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}


body {
  margin: 0;
  font-family: Metropolis;
  letter-spacing: normal;
  // line-height: 24px;
  line-height: 1.2rem;
  background-color: #fafafa;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.cdk-overlay-container {

  .cdk-overlay-backdrop,
  .cdk-overlay-dark-backdrop,
  .cdk-overlay-backdrop-showing {
    background: rgba(0, 0, 0, 0.85);
  }
}

.dialog-background {
  background: rgba(0, 0, 0, 0.2) !important;
}

.toast-pannel {
  border: none;
  background: none;
}

.cdk-overlay-pane-moreoption {
  border: none;
}

.wrapper {
  cursor: help;
  text-align: left;
  width: 25px;
  height: 25px;
  // margin-top: 15px;
  // margin-left: 10px;
  padding-left: 8px;
  padding-top: 12px;
  -webkit-transform: translateZ(0);
  -webkit-font-smoothing: antialiased;

}

.wrapper .tooltip {
  background: #000;
  bottom: 100%;
  color: #fff;
  display: block;
  margin-bottom: 15px;
  margin-left: -120px;
  opacity: 0;
  padding: 10px;
  font-size: 13px;
  line-height: 20px;
  pointer-events: none;
  position: absolute;
  width: 150px;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all .25s ease-out;
  -moz-transition: all .25s ease-out;
  -ms-transition: all .25s ease-out;
  -o-transition: all .25s ease-out;
  transition: all .25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.wrapper .tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

/* CSS Triangles - see Trevor's post */
.wrapper .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #000 10px;
  bottom: -10px;
  content: " ";
  height: 0;
  left: 80%;
  margin-left: -13px;
  position: absolute;
  width: 0;
}

.wrapper:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

//   tr:nth-child(even) {background-color: #fafafa;}
.mat-tab-label {
  opacity: 1 !important;
}

span .assets-panel {
  font-weight: bold;
}

.hr {
  border: 0.5px solid #e9e9e9;
  margin: 10px 18px 10px;
}

.mat-expansion-panel-header-title img {
  margin-right: 5px;
}

.d-flex {
  display: flex;
  align-items: center;
}

.accordion__header img {
  margin-right: 5px;
  height: 16px;
}

.footer-top .nav-tabs {
  margin-bottom: 0 !important;
}

.mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
  top: 6px !important;
}

.mat-accordion .mat-expansion-panel .mat-expanded .mat-expansion-indicator {
  bottom: 10px !important;
}

.sideNav {
  background-color: #e8e8e8 !important;
}

.mat-expansion-panel {
  background: #e8e8e8 !important;
}

.mat-accordion .mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body .Fields ul li:hover {
  background-color: #fff !important;
  border-left: 3px solid #0072A3 !important;
}

.mat-accordion .mat-expansion-panel:first-of-type,
.mat-accordion .mat-expansion-panel {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body .Fields ul li .cardinality {
  color: #fff;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  min-width: 0.75rem;
  background: #00364d;
  height: 0.75rem;
  line-height: normal;
  border-radius: 0.5rem;
  font-size: 0.5rem;
  padding: 0.1rem 0.2rem 0;
  margin-right: 0.3rem;
  white-space: nowrap;
  text-align: center;
}

.mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-content .mat-expansion-panel-header-title {
  font-weight: 600;
  font-size: 13px !important;
}

.mat-expansion-panel-body .Fields ul li {
  border-left: 3px solid transparent;
}

.filter-options {
  margin-right: 8px;
  margin-top: 5px;
}

.mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-content .mat-expansion-panel-header-description .filter-options .apply-filter {
  margin-right: 8px !important;
}

.mat-dialog-actions .cancel,
.footer-wrapper .cancel {
  min-width: 3.6rem;
  max-width: 18rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  line-height: 1.8rem;
  letter-spacing: 0.12em;
  font-size: 13px !important;
  font-weight: 500;
  height: 2rem !important;
  padding: 3px 0.6rem 0;
  transition: all 0.3s ease;
}

.mat-dialog-actions .submit,
.footer-wrapper .submit {
  min-width: 3.6rem;
  max-width: 18rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  line-height: 1.8rem;
  letter-spacing: 0.12em;
  font-size: 13px !important;
  font-weight: 500;
  height: 2rem !important;
  padding: 3px 0.6rem 0;
  transition: all 0.3s ease;
}

.mat-menu-content:not(:empty) {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 0px;
  padding-left: 0px;
  background-color: #fafafa;
}

.mat-menu-item {
  line-height: 36px !important;
  height: 36px !important;
}

.mat-menu-item {
  font-family: metropolis, "Helvetica Neue", sans-serif !important;
  font-size: 12px !important;
  // font-weight: 500!important;
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: #CCCCCC !important;
}

.mat-menu-panel {
  margin-top: 8px;
  background-color: #e8e8e8;
}

.cdk-overlay-pane {
  &.mat-tooltip-panel {


    .mat-tooltip {
      // left: 0;
      // margin:  0 0 0;
      // margin-left: 20px;
      font-family: metropolis !important;
      // color: rgb(255, 255, 255);
      background-color: rgb(0, 0, 0);
      border-radius: 3px;
      // font-size: 12px;
      padding: 5px;
      min-width: 100px;
      text-align: center;
      white-space: pre-line;
    }

  }

}

.pane table,
.pane .rule-create-container,
.pane .rule-view-container,
.pane {
  animation: fadeInRight 100ms ease-in-out !important;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(0px);
  }

  to {
    opacity: 1;
  }
}

.time-container .date-time-range .select .form-field1 .form-label-top1 {
  bottom: 85% !important;
}

input[type="search"]::-webkit-search-cancel-button {
  position: relative;
  right: 15px;
}

.text-danger {
  color: #db2100;
}

// .preview-container .dashboard-label-container .body-wrapper{
//  min-height: 180px!important;
// }
.rule-container .nav-tabs,
.settings-container .nav-tabs {
  margin-bottom: 0;
}

.create-rule-container .nav-tabs {
  margin-top: 0;
}

// d3 chart styles

// d3 chart styles
$parent-bubble-color: #e1a6a6;
$bubble-color: #888;
$empty-bubble-color: rgb(211, 211, 211);

$circle-hovered-color: #f0c400;
$circle-clicked-color: #13abec;
$circle-clicked-predecessors-color: #13abec;
$circle-hovered-predecessors-color: #f0c400;

.circle {
  cursor: pointer;
  // fill: #93bac5;
  fill: #dce7ef;
}

.outer-circle {
  cursor: pointer;
  fill: #c7dceb;
}

.inner-circle {
  cursor: pointer;
  fill: #c7dceb;
}

.outer-circle-selected {
  cursor: pointer;
  fill: $parent-bubble-color;
}

.inner-circle-selected {
  cursor: pointer;
  fill: #e1a6a6;

}

.parent-bubble {
  cursor: none;
  visibility: hidden;
}

text {
  font-size: 12px;
  font-family: Metropolis;
}

path {
  // fill: #bfbfbf;
  fill: #eee;
  stroke: #d5d5d5;
  stroke-width: 1px;

  &:hover {
    fill: #e6e6e6;
  }
}

.bubble {
  cursor: pointer;
  fill: $bubble-color;
}

.empty-bubble {

  fill: $empty-bubble-color;
  stroke-width: 0.5px;
  stroke: $bubble-color;
}


.circle-clicked {
  cursor: pointer;
  fill: $circle-clicked-color !important;
}


.circle-clicked-predecessors {
  cursor: pointer;
  fill: $circle-clicked-predecessors-color !important;
}

.circle-hovered-predecessors {
  cursor: pointer;
  fill: $circle-hovered-predecessors-color !important;
}

.circle-hovered {
  cursor: pointer;
  fill: $circle-hovered-color !important;
}

.circle-selected {
  cursor: pointer;
  fill: #c59393;
}

.circle-selected-viewed {
  stroke-width: 2px;
  stroke: #03A9F4;
}

.circle-hovered {
  stroke: $circle-hovered-color;
}

.circle-clicked {
  stroke: $circle-clicked-color !important;
}

.circle-clicked-predecessors {
  stroke: $circle-clicked-predecessors-color !important;
}


.d3menu,
.child-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  width: 220px;
}

.d3menu li {
  padding: 8px;
  cursor: pointer;
}

.d3menu li:hover {
  background-color: #f0f0f0;
}

.child-menu {
  display: none;
  position: absolute;
  top: 0;
  left: 120px;
  /* Position next to the parent menu */
  border: 1px solid #ddd;
}

.d3menu li:hover .child-menu {
  display: block;
  /* Show child menu on hover */
}

.exploration-node {
  .count {
    font-size: 16px;
    font-weight: 600;
    fill: rgb(86, 86, 86);
    alignment-baseline: middle;
  }
}